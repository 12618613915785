var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "steps-block" },
    [
      _c(
        "content-wrapper-block",
        [
          _c("div", { staticClass: "steps-block__header" }, [
            _c("h1", {
              staticClass: "steps-block__title",
              domProps: { innerHTML: _vm._s(_vm.title) },
            }),
          ]),
          _vm._l(_vm.steps, function (item, index) {
            return _c(
              "step-card",
              {
                key: index,
                attrs: {
                  id: item.id,
                  image: item.image,
                  current: index < 10 ? "0" + (index + 1) : index + 1,
                  reverse: index % 2 ? true : false,
                  max: _vm.steps.length,
                  title: item.title,
                  "has-button": item.hasButton,
                  "button-title": item.buttonTitle,
                  "click-func": item.clickFunc,
                },
              },
              [_vm._v(" " + _vm._s(item.desc) + " ")]
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }