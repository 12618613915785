var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "step-card" }, [
    _c(
      "div",
      {
        class: [
          "step-card__body",
          "step-body",
          { "step-card__reverse": _vm.reverse },
        ],
      },
      [
        _c(
          "div",
          { staticClass: "step-body__image" },
          [
            _c("img", {
              staticClass: "step-body__logo",
              attrs: { src: _vm.image },
            }),
            _vm.hasButton
              ? _c(
                  "ui-button",
                  {
                    staticClass: "button_mobile-shown",
                    attrs: {
                      "data-popup-with-chats": "",
                      "data-popup-with-chats-title": _vm.buttonTitle,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.buttonTitle) + " ")]
                )
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "step-body__content" }, [
          _c(
            "div",
            { staticClass: "step-body__counter custom-counter" },
            [
              _c("slide-counter", {
                staticClass: "custom-counter__block",
                attrs: { current: _vm.current, maximum: _vm.max },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "step-body__info" }, [
            _c("div", { staticClass: "step-body__title" }, [
              _vm._v(" " + _vm._s(_vm.title) + " "),
            ]),
            _c(
              "div",
              { staticClass: "step-body__description" },
              [_vm._t("default")],
              2
            ),
            _vm.hasButton
              ? _c(
                  "div",
                  { staticClass: "step-body__footer" },
                  [
                    _c(
                      "ui-button",
                      {
                        attrs: {
                          "data-popup-with-chats": "",
                          "data-popup-with-chats-title": _vm.buttonTitle,
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.buttonTitle) + " ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }