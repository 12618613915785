<template>
  <div class="step-card">
    <div
      :class="[
        'step-card__body',
        'step-body',
        { 'step-card__reverse': reverse },
      ]"
    >
      <div class="step-body__image">
        <img class="step-body__logo" :src="image" />
        <ui-button
          v-if="hasButton"
          class="button_mobile-shown"
          data-popup-with-chats
          :data-popup-with-chats-title="buttonTitle"
        >
          {{ buttonTitle }}
        </ui-button>
      </div>
      <div class="step-body__content">
        <div class="step-body__counter custom-counter">
          <slide-counter
            class="custom-counter__block"
            :current="current"
            :maximum="max"
          />
        </div>
        <div class="step-body__info">
          <div class="step-body__title">
            {{ title }}
          </div>
          <div class="step-body__description">
            <slot />
          </div>
          <div v-if="hasButton" class="step-body__footer">
            <ui-button
              data-popup-with-chats
              :data-popup-with-chats-title="buttonTitle"
            >
              {{ buttonTitle }}
            </ui-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiButton from "@/components/ui/UIButton";
import SlideCounter from "@/components/elements/SlideCounter";

export default {
  name: "StepCard",
  components: {
    UiButton,
    SlideCounter,
  },
  props: {
    image: {
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    current: {
      type: String,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    hasButton: {
      type: Boolean,
      default: true,
    },
    buttonTitle: {
      type: String,
      default: "Подробнее",
    },
    id: {
      type: String,
      default: "",
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    clickFunc: {
      type: Function,
      required: false,
    },
  },
  methods: {
    handleClick() {
      if (this.clickFunc) {
        this.clickFunc();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.custom-counter {
  &__block {
    width: max-content;
    background-color: transparent;
    color: #fff;
    padding: 0 25px 0 0;

    @media screen and (min-width: $screen-sm) {
      padding: 0 27px 7px 27px;
    }
  }
}
.step-card {
  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column-reverse;
    height: auto;

    @media screen and (min-width: $screen-sm) {
      flex-direction: row;
      height: 365px;
    }
  }
  &__reverse {
    flex-direction: column-reverse;
    height: auto;

    @media screen and (min-width: $screen-sm) {
      flex-direction: row-reverse;
      height: 365px;
    }
  }
  &__content {
    display: flex;
  }
}
.step-body {
  &__content {
    display: flex;
    width: 100%;

    @media screen and (min-width: $screen-sm) {
      width: 50%;
    }
  }
  &__counter {
    position: absolute;
    margin-right: 3px;

    @media screen and (min-width: $screen-sm) {
      position: static;
    }
  }
  &__logo {
    width: 100%;
  }
  &__image {
    width: 100%;
    margin-bottom: 54px;

    img {
      width: 100%;

      // @media screen and (min-width: $screen-lg) {
      //   width: auto;
      // }
    }
    @media screen and (min-width: $screen-sm) {
      width: 40%;
      margin-bottom: 0;
      pointer-events: auto;
    }
    .button_mobile-shown {
      @media screen and (min-width: $screen-sm) {
        display: none;
        pointer-events: auto;
      }
    }
  }
  &__title {
    font-family: "Bebas Neue", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 98%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 30px;
    margin-left: 60px;

    @media screen and (min-width: $screen-sm) {
      margin-left: 0;
    }
  }
  &__description {
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: 0.03em;
    color: rgba(255, 255, 255, 0.8);
  }
  &__footer {
    margin-top: 34px;
    display: none;

    @media screen and (min-width: $screen-sm) {
      display: block;
    }
  }
}
</style>
<style lang="scss">
.custom-counter {
  .slide-counter__current {
    &:after {
      background-color: #231f20;
      box-shadow: none;
    }
  }
}
</style>
