var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("steps-block", {
        attrs: {
          title: "приобретите апартаменты,<br>не&nbspвыходя из дома!",
          steps: _vm.steps,
        },
      }),
      _c("best-apartments-block"),
      _c("callback-block", {
        attrs: { id: "callback", "id-formblock": "question-block__callback" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }