<template>
  <div class="steps-block">
    <content-wrapper-block>
      <div class="steps-block__header">
        <h1 class="steps-block__title" v-html="title" />
      </div>

      <step-card
        v-for="(item, index) in steps"
        :id="item.id"
        :key="index"
        :image="item.image"
        :current="index < 10 ? '0' + (index + 1) : index + 1"
        :reverse="index % 2 ? true : false"
        :max="steps.length"
        :title="item.title"
        :has-button="item.hasButton"
        :button-title="item.buttonTitle"
        :click-func="item.clickFunc"
      >
        {{ item.desc }}
      </step-card>
    </content-wrapper-block>
  </div>
</template>

<script>
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import StepCard from "@/components/elements/StepCard";

export default {
  name: "StepsBlock",
  components: {
    ContentWrapperBlock,
    StepCard,
  },
  props: {
    title: String,
    steps: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.steps-block {
  background: #231f20;

  @media screen and (min-width: $screen-sm) {
    padding-bottom: 70px;
  }

  &__title {
    font-family: "Bebas Neue", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 34px;
    line-height: 120%;
    letter-spacing: 0.05em;
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 0;

    @media screen and (min-width: $screen-md) {
      font-size: 96px;
      line-height: 120%;
      margin-top: 41px;
      margin-bottom: 20px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
}
</style>
