<template>
  <div class="slide-counter">
    <span class="slide-counter__current">{{ current }}</span>
    <span class="slide-counter__divider" />
    <span class="slide-counter__maximum">{{ maximum }}</span>
  </div>
</template>

<script>
export default {
  name: "SlideCounter",
  props: ["current", "maximum"],
};
</script>

<style scoped lang="scss">
.slide-counter {
  background-color: #000000;
  padding: 7px 27px;
  display: inline-block;
  font-family: $font-family-bebas;
  overflow: hidden;
  position: relative;

  &__current {
    font-weight: bold;
    font-size: 32px;
    position: relative;

    &:after {
      content: " ";
      display: block;
      height: 31px;
      width: 20px;
      transform: rotate(45deg);
      border-left: 1px solid #cccccc;
      position: absolute;
      right: -15px;
      bottom: -10px;
      background-color: #000000;
      z-index: 1;
      box-shadow: 0 0 0 1px #000000;
    }
  }

  &__maximum {
    font-size: 14px;
    opacity: 0.8;
    position: relative;
    z-index: 2;
    top: 7px;
  }
}
</style>
