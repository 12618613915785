var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "slide-counter" }, [
    _c("span", { staticClass: "slide-counter__current" }, [
      _vm._v(_vm._s(_vm.current)),
    ]),
    _c("span", { staticClass: "slide-counter__divider" }),
    _c("span", { staticClass: "slide-counter__maximum" }, [
      _vm._v(_vm._s(_vm.maximum)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }