<template>
  <div class="">
    <steps-block
      title="приобретите апартаменты,<br>не&nbspвыходя из дома!"
      :steps="steps"
    />
    <best-apartments-block />
    <callback-block id="callback" id-formblock="question-block__callback" />
  </div>
</template>

<script>
import StepsBlock from "@/components/blocks/StepsBlock";
import CallbackBlock from "@/components/blocks/CallbackBlock";
import BestApartmentsBlock from "@/components/blocks/BestApartmentsBlock";

export default {
  name: "OnlineShoppingPage",
  components: {
    StepsBlock,
    CallbackBlock,
    BestApartmentsBlock,
  },
  metaInfo: {
    meta: [
      {
        name: "description",
        content: "Как купить уютные апартаменты в ЖК MainStreet онлайн.",
      },
    ],
  },
  data() {
    return {
      steps: [
        {
          image: require("../assets/images/online/first-image.png"),
          title: "Получите онлайн-консультацию",
          desc: "Менеджер покажет варианты отделки, расскажет об особенностях планировок и возможностях оплаты. Задавайте любые вопросы!",
          buttonTitle: "Заказать консультацию",
          id: "step-block__consultation",
          clickFunc: () => {
            this.$openPopupWithChat('Заказать консультацию');
          },
        },
        {
          image: require("../assets/images/online/sec-image.png"),
          title: "Забронируйте апартаменты",
          desc: `Выбранные апартаменты будут забронированы на Ваше имя. Подготовьте документы и
                 договоритесь о времени визита с менеджером.`,
          hasButton: true,
          buttonTitle: "Забронировать",
          id: "step-block__booking",
        },
        {
          image: require("../assets/images/online/third-image.png"),
          title: "Подпишите договор",
          desc: `Мы организуем визит так, чтобы Вы чувствовали себя в безопасности и комфорте.
                 Менеджер поможет заполнить документы, Вы подпишете договор и сделаете оплату.`,
          buttonTitle: "Запланировать визит",
          id: "step-block__visit",
          clickFunc: () => {
            this.clickSecButtonTest();
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
